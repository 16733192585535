import React, { useEffect } from "react"

import Seo from "../components/seo"
import Layout from "../components/layout"

const SignUpPage = () => {
  useEffect(() => {
    const scriptA = document.createElement("script")
    const scriptB = document.createElement("script")

    scriptA.text = `var _ctct_m = "0ffa753d0525e7ca8903160edf8e0a92";`

    scriptB.src =
      "//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js"
    scriptB.id = "signupScript"
    scriptB.async = true
    scriptB.defer = true

    document.body.appendChild(scriptA)
    document.body.appendChild(scriptB)

    return () => {
      window.SignUpFormWidget = undefined
      document.body.removeChild(scriptA)
      document.body.removeChild(scriptB)
    }
  }, [])

  return (
    <Layout>
      <Seo
        title="Sign up"
        description="Sign up to receive updates from Lender Compliance Technologies"
      />
      <section className="grid grid-cols-12 gap-x-gs gap-y-5 md:gap-y-10 px-ogs pt-8 sm:pt-12 md:pt-18 pb-18">
        <div className="col-span-12 md:col-span-6">
          <h3 className="heading-alpha mb-3 md:mb-6 lg:mb-8">Sign up</h3>
          <div className="text-beta md:max-w-md space-y-4">
            <p>To receive updates from Lender Compliance Technologies</p>
          </div>
        </div>
        <div className="col-start-1 col-span-12 md:col-span-6 xl:col-start-7 xl:col-span-5 relative z-10">
          <div
            style={{ maxWidth: 762 + "px", width: 150 + "%" }}
            className="hidden md:block absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 z-10"
          >
            <svg
              className="w-full text-blue-light fill-current opacity-30 mx-auto"
              viewBox="0 0 762 762"
            >
              <path d="M381,762A381.09,381.09,0,0,1,232.69,30a381.09,381.09,0,0,1,296.62,702.1A378.66,378.66,0,0,1,381,762Zm0-732A351,351,0,0,0,132.81,629.19,351,351,0,1,0,629.19,132.81,348.66,348.66,0,0,0,381,30Z" />
            </svg>
          </div>
          <div className="relative bg-blue-light rounded-md px-5 py-6 sm:p-6 md:p-8 lg:pt-10 lg:pb-14 lg:px-12.5 shadow-contact z-20">
            <div
              id="ctct-inline-form"
              className="ctct-inline-form"
              data-form-id="a43071ad-17f3-4ca3-a574-9c70926411b5"
            ></div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SignUpPage
